.footer.page-footer{
	background-color: black;
}

.item-zoom {
	position: relative;
	overflow: hidden;
	min-width: 100%;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	background: linear-gradient(to bottom, transparent 100%, black 100%);
}

.item-zoom img {
	vertical-align: top;
	max-width: 100%;
	-moz-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	background: linear-gradient(to bottom, transparent 0%, black 100%);

}

.item-zoom:hover img {
	
}

.nav-link{
    color: #fff !important;
	font-weight: 400;
	margin:0px 10px
  }
.nav-link.active{
	color: #f33141 !important;
}

.DropdownButton{
	color: #fff !important;
	font-weight: 400;
	margin:0px 10px
}

