@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer.page-footer{
	background-color: black;
}

.item-zoom {
	position: relative;
	overflow: hidden;
	min-width: 100%;
	box-sizing: border-box;
	background: linear-gradient(to bottom, transparent 100%, black 100%);
}

.item-zoom img {
	vertical-align: top;
	max-width: 100%;
	transition: all 0.3s;
	background: linear-gradient(to bottom, transparent 0%, black 100%);

}

.item-zoom:hover img {
	
}

.nav-link{
    color: #fff !important;
	font-weight: 400;
	margin:0px 10px
  }
.nav-link.active{
	color: #f33141 !important;
}

.DropdownButton{
	color: #fff !important;
	font-weight: 400;
	margin:0px 10px
}


